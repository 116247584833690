#cookiebar {
    font-size: 14px;
    font-family: helvetica, Arial, sans-serif;
    text-align: center;
    background-color: #222;
    color: #aaa;
    position: fixed;
    top: 89px;
    display: block;
    width: 100%!important;
    padding: 5px 20px;
    z-index: 1000;
}

#acceptcookies {
    cursor: pointer;
    display: inline;
    display: inline-block;
    color: #ccc;
    background-color: #444;
    border-color: #888;
    padding: 5px 10px;
    margin: 0 8px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

#moreinfocookies {
    cursor: pointer;
    text-decoration: underline;
}

